

import React,{useState,useEffect} from 'react'
import {getDuration, formatCountDown} from './helper'
import StatusColor from './StatusColor'
import { useAction } from 'hooks';
import { requestOrderListInterval } from 'store/actions/orderList'
import { Segment } from '@elevenia/master-ui/components/Atom'

const fallbackValue = (serviceType, channelOrder, config, orderType='') => {
  let fallbackDuration;
  switch(serviceType){
    case 'REGULAR':
      if(channelOrder==='INDOMARET') {
        fallbackDuration = config?.REGULAR_FALLBACK_MINUTES_AFTER_MIN_DELIVERY_TIME
        if(orderType==='FOOD') fallbackDuration = config?.REGULAR_FALLBACK_FOOD_MINUTES_AFTER_MIN_DELIVERY_TIME
      }
      if(channelOrder==='INDOGROSIR') fallbackDuration = config?.REGULAR_FALLBACK_MINUTES_AFTER_MIN_DELIVERY_TIME_IGR
      if(channelOrder==='OTHER') fallbackDuration = config?.REGULAR_FALLBACK_MINUTES_AFTER_MIN_DELIVERY_TIME_OTHER
      break
    case 'REGULAR-SLOT':
      if(channelOrder==='INDOMARET') {
        
        if(orderType==='FOOD') fallbackDuration = config?.REGULAR_SLOT_FALLBACK_FOOD_MINUTES_AFTER_MIN_DELIVERY_TIME
        if(orderType==='GROCERY') fallbackDuration = config?.REGULAR_SLOT_FALLBACK_GROCERY_MINUTES_AFTER_MIN_DELIVERY_TIME
      }
      break
    case 'REGULAR-PAKET':
      if(channelOrder==='INDOMARET') { 
        if(orderType==='PAKET') fallbackDuration = config?.PAKET_MINUTES_BEFORE_MAX_DELIVERY_TIME
        if(orderType==='PAKET-JEMPUT') fallbackDuration = config?.PAKET_JEMPUT_MINUTES_BEFORE_MAX_DELIVERY_TIME
      }
      break
    case 'EXPRESS':
      if(channelOrder==='INDOMARET') {
        fallbackDuration = config?.EXPRESS_FALLBACK_MINUTES_AFTER_MIN_DELIVERY_TIME
        if(orderType==='PAKET-EXPRESS') fallbackDuration = config?.PAKET_EXPRESS_FALLBACK
        if(orderType==='FOOD') fallbackDuration = config?.EXPRESS_FALLBACK_FOOD_MINUTES_AFTER_MIN_DELIVERY_TIME
        if(orderType==='GROCERY') fallbackDuration = config?.EXPRESS_FALLBACK_GROCERY_MINUTES_AFTER_MIN_DELIVERY_TIME
      }
      if(channelOrder==='INDOGROSIR') fallbackDuration = config?.EXPRESS_FALLBACK_MINUTES_AFTER_MIN_DELIVERY_TIME_IGR
      if(channelOrder==='OTHER') {
        fallbackDuration = config?.EXPRESS_FALLBACK_MINUTES_AFTER_MIN_DELIVERY_TIME_OTHER
        if(orderType==='FOOD') fallbackDuration = config?.EXPRESS_FALLBACK_FOOD_MINUTES_AFTER_MIN_DELIVERY_TIME_OTHER
        if(orderType==='GROCERY') fallbackDuration = config?.EXPRESS_FALLBACK_GROCERY_MINUTES_AFTER_MIN_DELIVERY_TIME_OTHER
      }
      break
    case 'EXPRESS-MULTIDROP':
      if(channelOrder==='OTHER') {
        if(orderType==='PAKET') fallbackDuration = config?.EXPRESS_MULTI_DROP_FALLBACK_PAKET_MINUTES_AFTER_MIN_DELIVERY_TIME_OTHER
        if(orderType==='FOOD') fallbackDuration = config?.EXPRESS_MULTI_DROP_FALLBACK_FOOD_MINUTES_AFTER_MIN_DELIVERY_TIME_OTHER
        if(orderType==='GROCERY') fallbackDuration = config?.EXPRESS_MULTI_DROP_FALLBACK_GROCERY_MINUTES_AFTER_MIN_DELIVERY_TIME_OTHER
      }
      break
    case 'SAMEDAY':
      if(channelOrder==='INDOMARET') {
        fallbackDuration = config?.SAMEDAY_FALLBACK
        if(orderType==="PAKET-EXPRESS") fallbackDuration = config?.PAKET_EXPRESS_MINUTES_BEFORE_MAX_DELIVERY_TIME
      }
      if(channelOrder==='INDOGROSIR') {
        fallbackDuration = config?.SAMEDAY_FALLBACK_IGR
        if(orderType==='GROCERY') fallbackDuration = config?.FALLBACK_MINUTES_BEFORE_CLOSING_HOURS
      }
      if(channelOrder==='OTHER') fallbackDuration = config?.SAMEDAY_FALLBACK_OTHER
      break
    case 'NEXTDAY':
      if(channelOrder==='INDOGROSIR') fallbackDuration = config?.FALLBACK_MINUTES_BEFORE_CLOSING_HOURS
      break
    default:
      fallbackDuration = ''
  }

  return fallbackDuration
}
const CountDown = ({
  category='-',
  type='GENERAL',
  startDuration,
  endDuration,
  serviceType='-',
  page='list-data',
  orderConfig,
  channelOrder='INDOMARET',
  orderType='-'
}) => {
  
  const durations = getDuration(startDuration,endDuration)
  const [timer, setTimes] =  useState(durations)
  const { hasFetch } = useAction();
  
  useEffect(()=>{
    let isMounted = true
    setInterval(()=>{
      
      isMounted && setTimes(prev=>{
        
        return prev-1
          
      })
    },[1000])
    return ()=> {isMounted=false}
  },[])
  
  
  useEffect(()=>{
    if(page==='list-data'){
      if(type!=='IN_DELIVERY'){
      
        (serviceType==='-' && timer===0) && hasFetch(requestOrderListInterval())
      }
    }
    
  },[hasFetch,timer,type,serviceType,page])
  const fallback =  fallbackValue(category, channelOrder, orderConfig, orderType )
  
  if(type==='IN_DELIVERY'){
    return <Segment width={92}>
      {
        StatusColor({
              duration:timer,
              text:formatCountDown(timer)
            })
      }
    </Segment>
  }else if(type==='PICKING_UP' || type==='READY_TO_DELIVER'){
    return <Segment width={92}>
        {
          timer <= fallback ? StatusColor({
                duration:timer,
                text:formatCountDown(timer)
              }) : '-'
        }
      </Segment>
  }else{
    return <Segment width={92}>
        {
          timer <= fallback ? StatusColor({
                duration:timer,
                text:formatCountDown(timer)
              }) : '-'
        }
      </Segment>
  }
}

export default CountDown;